import axios from 'axios';
import { getAuth, sendPasswordResetEmail, signOut } from 'firebase/auth';
import { auth } from './firebase';

const instance  = axios.create({
  baseURL: process.env.REACT_APP_API
});

instance.interceptors.request.use(async (config) => {
  const user = auth.currentUser

  if (user) {
    const token = await user.getIdToken(true);
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
})

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { response } = error;

    if (response?.status === 403) {
      const errorCode = response?.data?.code;

      if (
        errorCode === 'firebase-expired-token' ||
        errorCode === 'firebase-invalid-token'
      ) {
        try {
          const user = auth.currentUser;
          if (user) {
            const newToken = await user.getIdToken(true);
            instance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
            return instance(error.config); 
          } else {
            throw new Error('Usuario no autenticado');
          }
        } catch (refreshError) {
          await signOut(auth);
          window.location.replace('/sign-in');
        }
      }
    }
    return Promise.reject(error);
  }
);
export default instance;

export const upload = axios.create({
  baseURL: process.env.REACT_APP_UPLOAD_API
});



export const apiUrl = process.env.REACT_APP_API


export const resetPassword = (email, callback) => {
  const auth = getAuth();
  sendPasswordResetEmail(auth, email)
    .then(() => {
      // Correo de restablecimiento de contraseña enviado exitosamente.
      console.log("Correo de restablecimiento de contraseña enviado exitosamente");
      callback()
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // Manejar errores
      console.error("Error al enviar el correo de restablecimiento de contraseña:", errorCode, errorMessage);
    });
}
export const upgradePlan = async (name, country) => {
  let url = process.env.REACT_APP_SHEETY_API;
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      contact: {
        name: name,
        country: country,
      }
    })
  })
  .then((response) => { 
    return response.json()}
  )
}