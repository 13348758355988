import { useState, useEffect } from "react";
import { signOut } from "firebase/auth";
import { auth } from "utils/firebase";
import { api } from "utils/axios";
const useFetch = (url) => {
    const [data, setData] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [error, setError] = useState();
    const [toggle, setToggle] = useState();

    useEffect(() => {
      if(!url) return;
      setIsFetching(true);
      api.get(url)
        .then(response => setData(response.data))
        .catch(({response}) => {
          setError(response?.data);
          if(response?.data?.status === 403) {
            signOut(auth)
          } 
        })
        .finally(() => setIsFetching(false));
    }, [url, toggle]);
    
    const handleToggle = () => {
      setToggle(!toggle)
    }
    return { data, isFetching, error, handleToggle };
};
export { useFetch }
