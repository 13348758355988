import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useModal } from 'hooks/useModal';
import { useGetPlansQuery, useGetChestQuery } from 'store/api';
import countries from 'data/countries.json';
import StorageIndicator from 'components/StorageIndicator';
import Modal from 'components/Modal';
import UpgradeStorage from 'components/modals/UpgradeStorageModal';
import { format, getDaysRemaining, getFreeTrialDays } from 'utils/helpers';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { useSelector } from 'react-redux';

const AccountPlan = () => {
  const [show, setShow] = useState(false);
  const [input, setInput] = useState('');
  const [selected, setSelected] = useState();
  const [isAvailable, setIsAvailable] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPlan, setCurrenPlan] = useState('');
  const [suspended, setSuspended] = useState(false);
  const [haveTrial, setHaveTrial] = useState(false);
  const {
    data: chest = {},
  } = useGetChestQuery();

  const { onOpen: openConverAccountModal } = useModal('ConvertAccountModal');
  const { onOpen: openUpgradeStorageModal } = useModal('UpgradeStorageModal');

  const { data: plans = {} } = useGetPlansQuery(undefined, { refetchOnMountOrArgChange: true });
  const { data = {} } = useSelector((state) => state.auth.user);
  
  const handleSelectOption = (i) => {
    setSelected(countries[i]);
    setIsOpen(false);
  }

  const handleChange = (e) => setInput(e.target.value);
  const { t, i18n } = useTranslation();
  const toggleList = () => setIsOpen(!isOpen);

  const closeModal = () => {
    setShow(false);
    setIsOpen(false);
    setSelected();
    setInput('');
  }

  const upgrade = () => {
    let url = process.env.REACT_APP_SHEETY_API;
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        contact: {
          name: input,
          country: selected.code,
        }
      })
    })
    .then((response) => response.json()
    .then(() => {
        closeModal();
    }));
  }

  useEffect(() => {
    if (input !== '' && selected && selected.hasOwnProperty('country')) setIsAvailable(true);

    else setIsAvailable(false)

  }, [input, selected]);

  useEffect(() => {
    if (plans && data && data.subscription) {
      // if(data.ambassador || ['trial', 'promo'].includes(data.subscription?.plan?.name.split(' ').pop())){
      //   setCurrenPlan(data.subscription?.plan);
      // } else {
      //   const plan = plans?.plans?.filter(item => item.id === data?.subscription?.plan?.id);
      //   setCurrenPlan(plan[0]);
      // }
      setCurrenPlan(data.subscription.plan)
    }
  }, [plans, data ]);

  useEffect(() => {
    if(!data) return;
    
    setHaveTrial(data.is_on_initial_free_trial);
    if(!data.subscription) {
      setSuspended(!data.is_on_initial_free_trial);
      return;
    }

    setSuspended(!data.is_active || data.subscription.status === 'canceled');
  }, [data]);

  return (
    <>
      <Modal show={show} setShow={setShow} >
        <UpgradeStorage toggle={closeModal} countries={countries} toggleList={toggleList} selected={selected} onClick={upgrade}
          isOpen={isOpen} handleChange={handleChange} handleSelectOption={handleSelectOption} disabled={!isAvailable} />
      </Modal>
      <div className='container-accountPlan'>
        <h4 className='text-[22px] !font-archivo !font-semibold !normal-case xl:'>{t('account.my_plan')}</h4>
        <div className='flex gap-y-8 md:gap-x-14 flex-wrap xl:flex-nowrap flex-col md:flex-row'>
          <div className='space-y-4 flex flex-col gap-3 w-[50%] '>
            <h5 className='mt-5 text-neutral-silver-200 !font-archivo !text-base'>{t('account.current_plan')}</h5>
            <div className='flex flex-col md:flex-row gap-4'>
              <div className='flex items-center space-x-2'>
            {currentPlan && !suspended && (
              <div className='flex flex-col gap-4'>
                <div className='grow'>
                  {data?.ambassador ?
                      <h5 className='!text-lg !capitalize !font-archivo'>
                        {t('global.ambassador')}
                      </h5>
                    :
                    <>
                      <h5 className='!text-lg !capitalize !font-archivo'>
                        {t(`plans.${currentPlan.billing_frequency}.title.${currentPlan.name}`)}
                      </h5>
                      <div className='text-neutral-silver-300 text-sm'>
                        {t('account.plan_description', {
                          price: currentPlan.pricing_data.price,
                          recurrence: t(`global.${currentPlan.name.split(' ').shift()}`),
                          currency: currentPlan.pricing_data.currency,
                        })}
                      </div>
                    </>
                  }
                </div>
                {!data?.ambassador &&
                  <div>
                    <Link
                      to='subscription'
                      className='text-brand-gold text-lg font-semibold'
                      style={{ 'padding-top': '6px', 'padding-bottom': '6px', 'display': 'block' }}>
                      {t('account.manage_subscription')}
                    </Link>
                  </div>
                }
              </div>
            )}
            {suspended && data.subscription && !data.ambassador && ( 
              <div className='flex flex-col gap-2'>
                <div className='flex flex-row gap-1.5 grow'>
                  <div>
                    <ExclamationCircleIcon className='h-5 w-5 text-error-red hidden lg:block' /> {/* unificar */}
                  </div>
                  <div className=' text-error-red'>
                    {data.subscription.status !== 'canceled' ? t('account.ended_title') : 
                      <>
                        {t('account.suspended_title')} {format.dateDdMmYyyy(data.subscription.expiration)}
                      </>
                    }
                  </div>
                </div>
                <div>
                  <Link
                    to='subscription/plan'
                    className='text-brand-gold text-lg font-semibold'
                    style={{ 'padding-top': '6px', 'padding-bottom': '6px', 'display': 'block' }}>
                    {t('account.choose_plan')}
                  </Link>
                </div>
              </div>
            )}
            {!data.subscription && 
            (
                <div className='flex flex-col gap-2'>
                  <h5 className='!text-lg !font-archivo'>
                    {t('account.free_trial')}
                  </h5>
                  <div className='text-neutral-silver-300 text-sm'>
                    {t(`account.free_trial_${haveTrial ? 'end' : 'ended'}`, { date: getDaysRemaining(data.initial_free_trial_ends_at) })}
                  </div>
                  {!haveTrial && 
                  <>
                    <div className='flex flex-row gap-1.5 grow'>
                        <div>
                          <ExclamationCircleIcon className='h-5 w-5 text-error-red hidden lg:block' />
                        </div>
                         <div className=' text-error-red'>
                            {t('account.free_trial_is_ended')}
                         </div>
                     </div>
                    <div>
                        <Link
                          to='subscription/plan'
                          className='text-brand-gold text-lg font-semibold'
                          style={{ 'padding-top': '6px', 'padding-bottom': '6px', 'display': 'block' }}>
                          {t('account.choose_plan')}
                        </Link>
                    </div>
                  </>
                  }
                </div>
            )}
            {data?.type == 'fan' && (
              <div className='mt-2'>
                <button
                  type='button'
                  className='text-brand-gold text-lg font-semibold py-1.2'
                  onClick={openConverAccountModal}>
                  {t('global.become an artist.default')}
                </button>
              </div>
            )}
          </div>
        </div>
        
        </div>
        {data?.type == 'artist' && (
            <div className='flex flex-col justify-between'>
              <h5 className='mt-5 text-neutral-silver-200 !text-base !font-archivo'>{t('account.storage')}</h5>
              <div className='flex items-center gap-x-4 grow'>
                <StorageIndicator
                  usedSpace={chest?.used_seconds}
                  totalSpace={chest?.total_seconds}
                  upgrade={false}
                  reverse />
              </div>
              <div>
                {/* {currentPlan && currentPlan.name === 'mensual' && (
                  <button
                    type='button'
                    className='text-brand-gold font-archivo text-lg font-semibold py-1.5'
                    onClick={() => { openUpgradeStorageModal() }}>
                    {t('account.upgrade')}
                  </button>
                )} */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default AccountPlan