import Chest from 'components/Chest';
import Uploader from 'components/Uploader';
import AccountSuspension from 'components/AccountSuspension';
import { useEffect, useState } from 'react';
import { useGetAccountQuery } from 'store/api';

export default function MyChest() {
  const [disabled, setDisabled] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const { 
    data: account, 
    isSuccess: isSuccessAccount } = useGetAccountQuery({}, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (isSuccessAccount) {
      if (!account) {
        setDisabled(true);
        return;
      } 
      // console.log(account.subscription.status)
      setIsActive(account.is_active)
      if(account.subscription && account?.is_active === true) {
        console.log('entre')
        setIsActive(account.subscription?.status !== 'canceled')
        return;
      }
      setDisabled(!account.is_active)
    }
  }, [account]);
  // console.log('is suspended', account.subscription)
  return (
    <>
      <div className='container flex flex-col gap-6 md:gap-10 py-8 lg:py-[60px]'>
        {!isActive && (
          <AccountSuspension
            isActive={isActive}
            expiration={account?.subscription?.expiration ?? account?.initial_free_trial_ends_at ?? null}
            subscription={account?.subscription}
          />
        )}
        <Uploader disabled={disabled} />
        <Chest />
      </div>
    </>
  )
}