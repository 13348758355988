import axios from 'axios';
import { auth } from './firebase';
import { signOut } from 'firebase/auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const upload = axios.create({
  baseURL: process.env.REACT_APP_UPLOAD_API
});


api.interceptors.request.use(async (config) => {
  const user = auth.currentUser

  if (user) {
    const token = await user.getIdToken(true);
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
})

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { response } = error;

    if (response?.status === 403) {
      const errorCode = response?.data?.code;

      if (
        errorCode === 'firebase-expired-token' ||
        errorCode === 'firebase-invalid-token'
      ) {
        try {
          const user = auth.currentUser;
          if (user) {
            const newToken = await user.getIdToken(true);
            api.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
            return api(error.config); 
          } else {
            throw new Error('Usuario no autenticado');
          }
        } catch (refreshError) {
          await signOut(auth);
          window.location.replace('/sign-in');
        }
      }
    }
    return Promise.reject(error);
  }
);
export { api, upload }