import { useEffect, useState } from 'react';
import { useModal } from 'hooks/useModal';
import ContextButton from 'components/ContextButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function ParticipantsActionsButtons({ participant, type }) {
  const { onOpen: openEditModal } = useModal('EditParticipantModal');
  const { onOpen: openDeleteModal } = useModal('DeleteParticipantModal');
  const [options, setOptions] = useState([{}])
  const [isOpened, setIsOpenned] = useState(false)
  const [suspended, setSuspended] = useState(false)
  const account = useSelector((state) => state.auth.user.data);

  const { t } = useTranslation();
  const toggleOptions = () => setIsOpenned(!isOpened);

  const closeOptions=() => setIsOpenned(false)

  const handleEditUser = () => {
    openEditModal({...participant, type})
  }

  const handleDeleteUser = () => {
    openDeleteModal({...participant, type});
  }



  const handleAction = (action) => {
    const option = options.find((opt) => opt.type === action);

    if (option && option.action && typeof option.action === 'function') {
      option.action();
    } else {
      console.error(`No valid action found for type: ${action}`);
    }
  }
  useEffect(() => {
    if (account.is_active) {
      setSuspended(false);
    } else {
      setSuspended(true);
    }
  }, [account]);
  
  useEffect(() => {
    if (participant.removable || type === 'invitation') {
      const newOptions = [
        { type: 'edit', description: t('global.edit'), action: handleEditUser, disabled: suspended }, 
        { type: 'delete', description: t('global.delete'), action: handleDeleteUser, disabled: suspended }
      ];
      setOptions(newOptions)
    } else {
      setOptions([{ type: 'edit', description: t('global.edit'), action: handleEditUser, disabled: suspended}])
    }
  }, [participant, suspended])

  return (
    <ContextButton options={options} action={handleAction} isOpened={isOpened} toggleOptions={toggleOptions} closeOptions={closeOptions} />
  )
}